<template>
  <div style="margin-top: 10px;">
    <el-row type="flex" class="row-bg" justify="end" style="text-align: right;margin-bottom: 5px">
      <el-col :span="24">
        <el-button type="plain" plain @click="showDrawer">列表</el-button>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="space-around">
      <el-col :span="24">
        <el-upload
            class="upload-demo"
            drag
            ref="upload"
            :auto-upload="false"
            action="/api/file/saveToTemp"
            :on-change="handleFileChange"
            :before-remove="removeFile"
            multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处</div>
          <div class="el-upload__text" style="color: red">单个文件限制500MB, 最大上传5GB</div>
          <el-progress v-if="showProgress" :percentage="uploadPercentage" :show-text="true"></el-progress>
        </el-upload>
        <el-button type="primary" plain @click="uploadFile">上传</el-button>
      </el-col>
    </el-row>

    <el-drawer
        title="文件列表"
        :visible.sync="drawer"
        size="100%">
      <div>
        <div class="block">
          <el-timeline :reverse="true">
            <el-timeline-item :timestamp="item.time" placement="top" v-for="(item, index) in cacheList" :key="index">
              <el-card>
                <h4 @click="toJump(item.path)">点击跳转</h4>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import ExpandIndex from "../../request/expand/index";
import {parseTime} from '@/utils';

export default {
  name: 'fileUpload',
  data() {
    return {
      ask: new ExpandIndex(),
      fileList: [],
      drawer: false,
      cacheList: [],
      showProgress: false,
      uploadPercentage: 0
    }
  },
  methods: {
    toJump(path) {
      this.$router.push({
        name: 'fkcShow',
        query: {
          to: path
        }
      })
    },
    showDrawer() {
      this.drawer = true;
      this.cacheList = this.$storageHandler.getLocalStorage("file_kc");
    },
    handleFileChange(file, fileList) {
      this.fileList.push({
        "name": file.name,
        "file": file.raw
      })
    },
    removeFile(file, fileList) {
      const index = this.fileList.findIndex((profile) => profile.name === file.name);
      if (index !== -1) {
        this.fileList.splice(index, 1)
      }
    },
    uploadFile() {
      let files = []
      const formData = new FormData();
      this.fileList.forEach(item => {
        formData.append('file', item.file);
      })
      if (this.$store.state.user.isLogin) {
        formData.append('directory', this.$store.getters["user/getUserInfo"].lUsrId);
      }
      const xhr = new XMLHttpRequest();
      this.showProgress = true
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          this.uploadPercentage = Math.round((event.loaded * 100) / event.total);
        }
      });
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            let result = JSON.parse(xhr.response);
            let cacheFilePath = this.$storageHandler.getLocalStorage("file_kc");
            cacheFilePath = cacheFilePath == null ? [] : cacheFilePath;
            let time = parseTime(new Date(), '{y}-{m}-{d} {h}:{i}')
            cacheFilePath.push({
              "path": result.msg,
              "time": time
            })
            this.$storageHandler.setLocalStorage("file_kc", cacheFilePath);
            this.toJump(result.msg);
          } else {
            this.$message({
              showClose: true,
              message: '文件上传失败',
              type: 'error'
            });
          }
        }
      };

      xhr.open('POST', "/api/file/saveToFKC", true);
      xhr.send(formData);


      // this.$refs.upload.submit();
      // this.ask.filesUpload(formData).then(result => {
      //   let cacheFilePath = this.$storageHandler.getLocalStorage("file_kc");
      //   cacheFilePath = cacheFilePath == null ? [] : cacheFilePath;
      //   let time = parseTime(new Date(), '{y}-{m}-{d} {h}:{i}')
      //   cacheFilePath.push({
      //     "path": result.msg,
      //     "time": time
      //   })
      //   this.$storageHandler.setLocalStorage("file_kc", cacheFilePath);
      //   this.toJump(result.msg);
      // })
    }
  }
}
</script>
<style scoped>
.row-bg {
  text-align: center;
}
</style>
